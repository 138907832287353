import { Text, VStack } from '@chakra-ui/react';
import { ApprovalRequestScope } from '@jurnee/common/src/dtos/approvalRequests';
import { formatDate, getCurrentTimeZone } from '@jurnee/common/src/utils/dates';
import { getUserLabel } from '@jurnee/common/src/utils/user';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { ApprovalRequestData } from 'src/store/approvalRequests/approvalRequests.selectors';

interface Props {
  approvalRequest: ApprovalRequestData;
  scope: ApprovalRequestScope;
}

const dateOptions = {
  displayTime: true,
  displayTimeZone: true,
  timeZone: getCurrentTimeZone()
};

export function ApprovalRequestDetails({ approvalRequest, scope }: Props) {
  const { t } = useTranslation('approvalRequests', { keyPrefix: 'details' });

  const requestedTo = useMemo(() => {
    const users = approvalRequest.approvalResponses.map(({ userId }) => approvalRequest.users.find(user => user.id === userId));
    return users.map(getUserLabel).join(', ');
  }, [approvalRequest]);

  const requestedAt = formatDate(new Date(approvalRequest.createdAt), dateOptions);
  const requestedBy = getUserLabel(approvalRequest.users.find(user => user.id === approvalRequest.userId));

  return (
    <VStack w="100%" p={5} borderRight="1px solid" borderColor="blue.50">
      <VStack w="100%" spacing={2}>
        {
          scope === 'company' &&
            <VStack w="100%" spacing={0} alignItems="flex-start">
              <Text fontWeight={700}>{t('requestedTo')}</Text>
              <Text color="gray.400">{requestedTo}</Text>
            </VStack>
        }

        <VStack w="100%" spacing={0} alignItems="flex-start">
          <Text fontWeight={700}>{t('requestedBy')}</Text>
          <Text color="gray.400">{requestedBy}</Text>
        </VStack>

        <VStack w="100%" spacing={0} alignItems="flex-start">
          <Text fontWeight={700}>{t('requestedOn')}</Text>
          <Text color="gray.400">{requestedAt}</Text>
        </VStack>

        {
          approvalRequest.comment &&
            <VStack w="100%" spacing={0}>
              <Text w="100%" fontWeight={700}>
                { t('requestComment') }
              </Text>

              <Text w="100%" color="gray.400">
                { approvalRequest.comment }
              </Text>
            </VStack>
        }
      </VStack>
    </VStack>
  );
}