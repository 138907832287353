import { FETCH_STATUS } from '@jurnee/common/src/browser/State';
import { ApprovalRequestJSON } from '@jurnee/common/src/entities/ApprovalRequest';
import { createSlice } from '@reduxjs/toolkit';
import { initialState } from '../state';
import { getApprovalRequests, updateApprovalResponse, updateApprovalResponses } from './approvalRequests.thunks';

const slice = createSlice({
  name: 'approvalRequests',
  initialState: initialState.approvalRequests,
  reducers: {},
  extraReducers: (builder) => {

    builder.addCase(getApprovalRequests.pending, () => {
      return {
        list: [],
        relationships: null,
        error: null,
        status: FETCH_STATUS.FETCHING
      };
    });

    builder.addCase(getApprovalRequests.fulfilled, (_state, action) => {
      const { list, relationships } = action.payload;

      return {
        list,
        relationships,
        error: null,
        status: FETCH_STATUS.FETCHED
      };
    });

    builder.addCase(getApprovalRequests.rejected, () => {
      return {
        list: [],
        relationships: null,
        error: true,
        status: FETCH_STATUS.INITIAL
      };
    });

    builder.addCase(updateApprovalResponse.fulfilled, (state, action) => {
      return {
        ...state,
        list: state.list.reduce((out, approvalRequest) => {
          if (action.meta.arg.scope === 'approver') {
            const isUpdatedApprovalRequest = approvalRequest.approvalResponses.find(({ id }) => id === action.payload.id);

            if (!isUpdatedApprovalRequest) {
              out.push(approvalRequest);
            }

            return out;
          }

          const approvalResponses = approvalRequest.approvalResponses.map(ar => ar.id === action.payload.id ? action.payload : ar);

          if (approvalResponses.some(({ status }) => ['INITIAL', 'PENDING'].includes(status))) {
            out.push({ ...approvalRequest, approvalResponses });
          }

          return out;
        }, [] as ApprovalRequestJSON[])
      };
    });

    builder.addCase(updateApprovalResponses.fulfilled, (state, action) => {
      return {
        ...state,
        list: state.list.reduce((out, approvalRequest) => {
          if (approvalRequest.id !== action.payload.id) {
            out.push(approvalRequest);
          } else if (action.payload.approvalResponses.some(({ status }) => ['INITIAL', 'PENDING'].includes(status))) {
            out.push(action.payload);
          }

          return out;
        }, [] as ApprovalRequestJSON[])
      };
    });
  }

});

export default slice.reducer;