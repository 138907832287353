import { HStack, Link, Text } from '@chakra-ui/react';
import { Icon } from '@jurnee/common/src/components/Icon';
import { BookingJSON } from '@jurnee/common/src/entities/Booking';
import { useTranslation } from 'react-i18next';
import { Link as ReactRouterLink } from 'react-router-dom';

interface Props {
  bookingId: BookingJSON['id'];
}

export function EventDetailsLink({ bookingId }: Props) {
  const { t } = useTranslation('approvalRequests');

  return (
    <Link to={`/bookings/${bookingId}`} as={ReactRouterLink} target="_blank">
      <HStack color="blue.400" _hover={{ 'svg path': { fill: 'blue.600' }, color: 'blue.600' }} spacing="6px">
        <Text fontWeight={500}>{t('eventDetails')}</Text>
        <Icon icon="externalLink" color="blue.400" size={5} />
      </HStack>
    </Link>
  );
}