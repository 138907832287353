import { api } from '@jurnee/common/src/browser/Api';
import { CompanyUpdateBody } from '@jurnee/common/src/dtos/companies';
import { EntityUpdateBody, ProviderDiscountAddBody } from '@jurnee/common/src/dtos/entities';
import { ApprovalRequestJSON, ApprovalRequestRelationshipsJSON } from '@jurnee/common/src/entities/ApprovalRequest';
import { BookingJSON, BookingRelationshipsJSON } from '@jurnee/common/src/entities/Booking';
import { Company, CompanyJSON } from '@jurnee/common/src/entities/Company';
import { Entity, EntityJSON } from '@jurnee/common/src/entities/Entity';
import { ProviderDiscountJSON } from '@jurnee/common/src/entities/ProviderDiscount';
import { ProviderSetupIntent } from '@jurnee/common/src/entities/ProviderEntity';
import { MostFavoriteList } from '@jurnee/common/src/entities/UserFavoriteExperience';
import { List } from '@jurnee/common/src/serializers';

export default {
  getCompany(companyId: Company['id']): Promise<CompanyJSON> {
    return api.get(`companies/${companyId}`);
  },
  update(companyId: Company['id'], company: CompanyUpdateBody): Promise<CompanyJSON> {
    return api.put(`companies/${companyId}`, company);
  },
  getBookingsByCompany(companyId: Company['id'], params: URLSearchParams): Promise<List<BookingJSON, BookingRelationshipsJSON>> {
    return api.get(`companies/${companyId}/bookings?${params.toString()}`);
  },
  createProviderSetupIntent(companyId: Company['id'], entityId: Entity['id']): Promise<ProviderSetupIntent> {
    return api.post(`companies/${companyId}/entities/${entityId}/setup-intents`);
  },
  getFavoriteExperiences(companyId: Company['id']): Promise<MostFavoriteList> {
    return api.get(`companies/${companyId}/favorite-experiences`);
  },
  getEntities(companyId: Company['id']): Promise<List<EntityJSON, never>> {
    return api.get(`companies/${companyId}/entities`);
  },
  updateEntity(companyId: Company['id'], entityId: Entity['id'], body: EntityUpdateBody): Promise<EntityJSON> {
    return api.put(`companies/${companyId}/entities/${entityId}`, body);
  },
  addProviderDiscount(companyId: Company['id'], entityId: Entity['id'], body: ProviderDiscountAddBody): Promise<ProviderDiscountJSON> {
    return api.post(`companies/${companyId}/entities/${entityId}/provider-discount`, body);
  },
  getProviderDiscounts(companyId: Company['id']): Promise<List<ProviderDiscountJSON, never>> {
    return api.get(`companies/${companyId}/provider-discounts`);
  },
  getApprovalRequestsByCompany(companyId: Company['id'], params: URLSearchParams): Promise<List<ApprovalRequestJSON, ApprovalRequestRelationshipsJSON>> {
    return api.get(`companies/${companyId}/approval-requests?${params.toString()}`);
  },
};