import { Box, Divider, Heading, VStack } from '@chakra-ui/react';
import { FETCH_STATUS } from '@jurnee/common/src/browser/State';
import { Loader } from '@jurnee/common/src/components/Loader';
import { BookingJSON } from '@jurnee/common/src/entities/Booking';
import { ExperienceJSON, ExperiencesSearchParams } from '@jurnee/common/src/entities/Experience';
import { isEmpty } from '@jurnee/common/src/utils/strings';
import * as React from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import EmptyState from 'src/components/EmptyState';
import ExperienceCard from 'src/components/Experience/ExperienceCard';
import ExperienceCardSkeleton from 'src/components/Experience/ExperienceCardSkeleton';
import ExperiencesGrid from 'src/components/Experience/ExperiencesGrid';
import PartnerPlaceCard from 'src/components/Experience/PartnerPlaceCard';
import { SearchBarParams } from 'src/components/SearchBar';
import { PrimaryButton } from 'src/components/buttons';
import { CustomItemModal } from 'src/modals/CustomItemModal';
import { RouteProps } from 'src/pages/Route';
import router from 'src/router';
import { TrackEventOptions, trackEvent } from 'src/store/analytics/analytics.thunks';
import { ExperiencesFiltersParams, getExperiences } from 'src/store/experiences/experiences.thunks';
import { getLanguagesThunk } from 'src/store/languages/languages.thunks';
import { PlacesTextSearchPayload, searchPlaces } from 'src/store/places/places.thunks';
import { RootState } from 'src/store/state';
import { getCompanyTags } from 'src/store/tags/tags.thunks';
import { getUserFavoriteExperiences } from 'src/store/userFavoriteExperiences/userFavoriteExperiences.thunks';
import { DEFAULT_EXPERIENCES_PATH, DEFAULT_EXPERIENCE_PARTICIPANTS, DEFAULT_EXPERIENCE_PRICE, ExperienceCategory, hasLocationFilter } from 'src/utils/experiences';
import { filterDuplicatePlaces } from 'src/utils/places';
import ExperiencesFilters from './ExperiencesFilters';
import { ExperiencesHeader } from './ExperiencesHeader';

interface StateProps {
  company: RootState['company'];
  experiences: RootState['experiences'];
  geolocation: RootState['geolocation'];
  languages: RootState['languages'];
  places: RootState['places'];
  user: RootState['user'];
  userFavoriteExperiences: RootState['userFavoriteExperiences'];
}

interface DispatchProps {
  getCompanyTags(): void;
  getExperiences(params: URLSearchParams): void;
  getLanguagesThunk(): void;
  getUserFavoriteExperiences(): void;
  searchPlaces(data: PlacesTextSearchPayload): void;
  trackEvent(opts: TrackEventOptions): void;
}

export interface State {
  hasFilters: boolean;
  filters: ExperiencesFiltersParams;
  placesSearched: boolean;
}

type ExperiencesRouteProps = Omit<RouteProps, 'location'> & {
  location: Omit<RouteProps['location'], 'state'> & {
    state: {
      booking?: BookingJSON;
      tagIds?: number[];
    }
  }
}

export type Props = State & StateProps & DispatchProps & ExperiencesRouteProps & WithTranslation;

export const defaultState: State = {
  hasFilters: false,
  filters: {
    languageId: undefined,
    tagIds: undefined,
    price: DEFAULT_EXPERIENCE_PRICE,
    participants: DEFAULT_EXPERIENCE_PARTICIPANTS,
    type: undefined,
    query: '',
    location: {
      description: '',
      lat: undefined,
      long: undefined,
      radius: 50000
    },
  },
  placesSearched: false
};

class Experiences extends React.Component<Props> {

  state: State = {
    ...defaultState,
    filters: {
      ...this.defaultFilters,
      location: {
        ...this.defaultFilters.location,
        description: [this.props.geolocation.data.city, this.props.geolocation.data.countryCode].join(', '),
        lat: this.props.geolocation.data.latitude,
        long: this.props.geolocation.data.longitude
      },
      type: this.experiencesType,
      currency: this.props.user.currency.id
    }
  };

  componentDidMount() {
    if (!this.props.match.params?.type) {
      return router.navigate(DEFAULT_EXPERIENCES_PATH);
    }

    this.init();
  }

  async init() {
    const filters = { ...this.state.filters, ...this.searchParamsFilters };

    this.setState({ ...this.state, filters });

    const params = this.getParams(filters);

    if (this.props.languages.status !== FETCH_STATUS.FETCHED) {
      await this.props.getLanguagesThunk();
    }

    if (this.props.company.tags.status !== FETCH_STATUS.FETCHED) {
      await this.props.getCompanyTags();
    }

    if (this.props.userFavoriteExperiences.status !== FETCH_STATUS.FETCHED) {
      await this.props.getUserFavoriteExperiences();
    }

    await this.props.getExperiences(params);

    return this.fetchPlaces(filters);
  }

  async componentDidUpdate(prevProps: Readonly<Props>): Promise<void> {
    if (this.props.match.params.type !== prevProps.match.params.type) {
      await this.init();
    }
  }

  get defaultFilters(): State['filters'] {
    return {
      ...defaultState.filters,
      type: this.experiencesType
    };
  }

  get searchParamsFilters() {
    const searchParams = new URLSearchParams(location.search);

    const languageId = Number(searchParams.get('languageId')) || this.state.filters.languageId;
    const price = Number(searchParams.get('price')) || this.defaultFilters.price;
    const participants = Number(searchParams.get('participants')) || this.defaultFilters.participants;
    const query = searchParams.get('query') || this.defaultFilters.query;
    const tagIds = this.getSearchParamsTagIds(searchParams);
    const locationName = searchParams.get('location') || this.state.filters.location.description;
    const lat = Number(searchParams.get('lat')) || this.state.filters.location.lat;
    const long = Number(searchParams.get('long')) || this.state.filters.location.long;
    const isCompanyRecommended = searchParams.get('isCompanyRecommended') === 'true';

    return {
      ...this.defaultFilters,
      languageId,
      price,
      participants,
      query,
      tagIds,
      isCompanyRecommended,
      location: {
        ...this.defaultFilters.location,
        description: locationName,
        lat,
        long
      }
    };
  }

  getSearchParamsTagIds(params: URLSearchParams) {
    return params.getAll('tagIds[]').map(Number)
      .filter(value => !isNaN(value) && value > 0);
  }

  getParams(filters: State['filters']): URLSearchParams {
    const params = new URLSearchParams();

    if (filters.type) {
      params.set('type', filters.type);
    }

    if (filters.languageId && this.experiencesType !== 'BOX_SHIPPING') {
      params.set('languageId', filters.languageId.toString());
    }

    if (!isEmpty(filters.query)) {
      params.set('query', filters.query);
    }

    if (filters.price !== DEFAULT_EXPERIENCE_PRICE) {
      params.set('price', filters.price.toString());
      params.set('currency', this.props.user.currency.id);
    }

    if (filters.participants !== DEFAULT_EXPERIENCE_PARTICIPANTS) {
      params.set('participants', filters.participants.toString());
    }

    if (Array.isArray(filters.tagIds) && filters.tagIds.length > 0) {
      for (const tagId of filters.tagIds) {
        params.append('tagIds[]', tagId.toString());
      }
    }

    if (this.hasLocationFilter && filters.location) {
      params.set('location', filters.location.description);
      params.set('lat', filters.location.lat.toString());
      params.set('long', filters.location.long.toString());

      if (filters.location.radius) {
        params.set('radius', filters.location.radius.toString());
      }
    }

    if (filters.isCompanyRecommended) {
      params.set('isCompanyRecommended', filters.isCompanyRecommended.toString());
    }

    return params;
  }

  applyFilters = async (appliedFilter: Partial<State['filters']>) => {
    const filters = {
      ...this.state.filters,
      ...appliedFilter
    };

    const params = this.getParams(filters);

    const hasFilters = !params.keys().next().done;

    if (hasFilters) {
      this.props.getExperiences(params);
    }

    this.setState({
      ...this.state,
      hasFilters,
      filters,
    });

    this.props.trackEvent({
      name: 'applied_experiences_filters',
      properties: filters as Record<string, boolean | number>
    });
  };

  get placesType() {
    if (this.state.filters.type === 'RESTAURANT') {
      return 'restaurant';
    }

    if (this.state.filters.type === 'BAR') {
      return 'bar';
    }

    return 'establishment';
  }

  fetchPlaces = async ({ query, location: { lat, long } }: ExperiencesSearchParams) => {
    if (!this.isFetchPlacesEnabled) {
      return;
    }

    if (isEmpty(query)) {
      if (this.state.filters.type === 'RESTAURANT') {
        query = 'group dinner';
      } else if (this.state.filters.type === 'BAR') {
        query = 'team drink';
      } else {
        return;
      }
    }

    this.setState({ ...this.state, placesSearched: true });

    const params = new URLSearchParams({
      query,
      type: this.placesType,
      lat: lat.toString(),
      long: long.toString(),
      radius: this.state.filters.location.radius.toString()
    });

    this.props.searchPlaces({
      params,
      onSuccess: () => {},
      onError: () => {}
    });
  };

  get experiencesType(): ExperienceJSON['type'] {
    if (!this.props.match.params?.type) {
      return 'IN_PERSON';
    }

    return this.props.match.params.type.toUpperCase().replace('-', '_') as ExperienceJSON['type'];
  }

  get hasLocationFilter() {
    return hasLocationFilter(this.experiencesType);
  }

  get loader() {
    return <Box h={500}><Loader /></Box>;
  }

  get isResultsLoading() {
    if (this.props.experiences.status !== FETCH_STATUS.FETCHED) {
      return true;
    }

    if (this.isFetchPlacesEnabled && this.state.placesSearched && this.props.places.status !== FETCH_STATUS.FETCHED) {
      return true;
    }

    return false;
  }

  get isNoMatchingPackage() {
    const isNoExperience = this.props.experiences.status === FETCH_STATUS.FETCHED && this.props.experiences.list.length === 0;
    const isNoPlace = this.props.places.status === FETCH_STATUS.FETCHED && this.props.places.list.length === 0;

    return isNoExperience && isNoPlace;
  }

  get isFetchPlacesEnabled() {
    return ['IN_PERSON', 'RESTAURANT', 'BAR'].includes(this.state.filters.type);
  }

  get skeletonExperiencesGrid() {
    return (
      <ExperiencesGrid>
        { Array(18).fill(0).map((_x, idx) => <ExperienceCardSkeleton key={idx} />) }
      </ExperiencesGrid>
    );
  }

  get partnerPlacesCards() {
    const filteredPlaces = filterDuplicatePlaces(this.props.places.list, this.props.experiences.list);

    return filteredPlaces.map((place, i) => {
      return (
        <CustomItemModal
          key={place.id}
          type="SPECIFIC_PARTNER"
          partnerPlace={place}
          booking={this.bookingOrigin}
        >
          <PartnerPlaceCard key={i} place={place} />
        </CustomItemModal>
      );
    });
  }

  renderCatalogExperiences() {
    if (this.props.experiences.list.length === 0) {
      return null;
    }

    return (
      <ExperiencesGrid>
        {this.props.experiences.list.map(experience => (
          <ExperienceCard
            key={experience.id}
            experience={experience}
            participants={this.state.filters.participants !== DEFAULT_EXPERIENCE_PARTICIPANTS ? this.state.filters.participants : null}
          />
        ))}
      </ExperiencesGrid>
    );
  }

  renderExperiences() {
    if (this.isResultsLoading) {
      return this.skeletonExperiencesGrid;
    }

    if (this.isNoMatchingPackage) {
      return this.noResults;
    }

    return (
      <VStack w="100%" spacing={8}>
        { this.renderCatalogExperiences() }
        { this.renderPlaces() }
      </VStack>
    );
  }

  renderPlaces() {
    if (!this.isFetchPlacesEnabled) {
      return null;
    }

    if (isEmpty(this.state.filters.query) && !['RESTAURANT', 'BAR'].includes(this.state.filters.type)) {
      return null;
    }

    if (this.props.places.list.length === 0) {
      return null;
    }

    return (
      <VStack w="100%" spacing={5} alignItems='flex-start'>
        {
          this.props.experiences.list.length > 0 &&
            [
              <Heading key="0" size="md">{this.props.t('places.heading')}</Heading>,
              <Divider key="1" />
            ]
        }

        <ExperiencesGrid>
          { this.partnerPlacesCards }
        </ExperiencesGrid>
      </VStack>
    );
  }

  onOpenFiltersModal = () => {
    this.props.trackEvent({
      name: 'opened_experiences_filters'
    });
  };

  handleSearchParams = (filters: ExperiencesFiltersParams) => {
    const params = this.getParams({ ...filters, location: { ...filters.location, radius: null }, type: null });

    router.navigate(`${this.props.location.pathname}?${params.toString()}`, { state: { booking: this.bookingOrigin } });
  };

  onSearchSubmitted = ({ query, cityParams }: SearchBarParams) => {
    const partial = cityParams ? {
      lat: cityParams.placeAddress.latitude,
      long: cityParams.placeAddress.longitude,
      description: cityParams.description
    } : {};

    const filters = {
      ...this.state.filters,
      query,
      location: {
        ...this.state.filters.location,
        ...partial
      }
    };

    this.handleSearchParams(filters);

    this.fetchPlaces(filters);

    this.applyFilters(filters);
  };

  onFiltersSubmitted = (filters: ExperiencesFiltersParams) => {
    this.handleSearchParams(filters);
    this.applyFilters(filters);
  };

  get bookingOrigin() {
    if (this.props.location.state && this.props.location.state.booking) {
      return this.props.location.state.booking;
    }

    return null;
  }

  onRemoveFilter = ({ currentTarget }: React.MouseEvent) => {
    const filters = { ...this.state.filters };

    if (currentTarget.id === 'languageId') {
      filters.languageId = defaultState.filters.languageId;
    }

    if (currentTarget.id === 'tagIds') {
      filters.tagIds = defaultState.filters.tagIds;
    }

    if (currentTarget.id === 'price') {
      filters.price = defaultState.filters.price;
    }

    if (currentTarget.id === 'participants') {
      filters.participants = defaultState.filters.participants;
    }

    this.handleSearchParams(filters);
    this.applyFilters(filters);
  };

  get results() {
    if (this.props.languages.status !== FETCH_STATUS.FETCHED) {
      return this.loader;
    }

    if (this.props.company.tags.status !== FETCH_STATUS.FETCHED) {
      return this.loader;
    }

    return (
      <VStack w="100%" px={8} pb={8} spacing={5}>
        <ExperiencesFilters
          languages={this.props.languages.list}
          tags={this.props.company.tags.list}
          filters={this.state.filters}
          currency={this.props.company.data.currency}
          isLoading={this.isResultsLoading}
          defaultCityValue={this.state.filters.location.description}
          hasCitySearch={this.state.filters.type !== 'VIRTUAL'}
          onRemoveFilter={this.onRemoveFilter}
          onSearchSubmitted={this.onSearchSubmitted}
          onFiltersSubmitted={this.onFiltersSubmitted}
        />

        { this.renderExperiences() }
      </VStack>
    );
  }

  onClick = () => {
    this.props.trackEvent({ name: `empty_state_${this.state.filters.type.toLowerCase()}_button_clicked` });
  };

  get noResults() {
    const type = ['RESTAURANT', 'BAR'].find((type) => this.state.filters.type === type) ?? 'CUSTOM_REQUEST';

    return (
      <EmptyState
        imagePath="/assets/illustrations/search.svg"
        heading={this.props.t('noResults.heading')}
        description={this.props.t('noResults.description')}
      >
        <CustomItemModal
          type={type as ExperienceCategory['type']}
          booking={this.bookingOrigin}
        >
          <PrimaryButton size="sm" onClick={this.onClick}>
            {this.props.t('noResults.cta')}
          </PrimaryButton>
        </CustomItemModal>
      </EmptyState>
    );
  }

  render() {
    return (
      <main>
        <ExperiencesHeader filters={this.state.filters} booking={this.bookingOrigin} />
        { this.results }
      </main>
    );
  }
}

function mapStateToProps(state: RootState): StateProps {
  return {
    company: state.company,
    experiences: state.experiences,
    geolocation: state.geolocation,
    languages: state.languages,
    places: state.places,
    user: state.user,
    userFavoriteExperiences: state.userFavoriteExperiences
  };
}

const mapDispatchToProps: DispatchProps = {
  getCompanyTags,
  getExperiences,
  getLanguagesThunk,
  getUserFavoriteExperiences,
  searchPlaces,
  trackEvent
};

export default connect<StateProps, DispatchProps, RouteProps, RootState>(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation('experiences')(Experiences));