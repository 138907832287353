import { Box, Divider, Grid, Heading, HStack, useToast, VStack } from '@chakra-ui/react';
import { ApprovalRequestScope } from '@jurnee/common/src/dtos/approvalRequests';
import { BudgetJSON } from '@jurnee/common/src/entities/Budget';
import { UserJSON } from '@jurnee/common/src/entities/User';
import { getBookingInvoicesTotalDetails } from '@jurnee/common/src/utils/bookingInvoices';
import { getBookingName } from '@jurnee/common/src/utils/bookings';
import { isBudgetVisible } from '@jurnee/common/src/utils/budgets';
import { getErrorToast, getSuccessToast } from '@jurnee/common/src/utils/toasts';
import { isAdmin } from '@jurnee/common/src/utils/user';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import BookingPriceDetailsCard from 'src/components/BookingPriceDetailsCard';
import { BookingBudgetCard } from 'src/components/Budget/BookingBudgetCard';
import { useAppDispatch } from 'src/store';
import { ApprovalRequestData } from 'src/store/approvalRequests/approvalRequests.selectors';
import { ApprovalResponsesUpdatePayload, ApprovalResponseUpdatePayload, getApprovalRequests, updateApprovalResponse, updateApprovalResponses } from 'src/store/approvalRequests/approvalRequests.thunks';
import { getUserSelector } from 'src/store/user/user.selectors';
import { isProcessed } from 'src/utils/approvalRequest';
import { TabKey } from '..';
import { ApprovalRequestDetails } from './ApprovalRequestDetails';
import { ApprovalResponseForm } from './ApprovalResponseForm';
import { ApprovalResponsesList } from './ApprovalResponsesList';
import { BookingItemsList } from './BookingItemsList';
import { EventDetailsLink } from './EventDetailsLink';

interface Props {
  approvalRequest: ApprovalRequestData;
  budgets: BudgetJSON[];
  scope: ApprovalRequestScope;
  tab: TabKey;
}

function isBudgetCardVisible(approvalRequest: ApprovalRequestData, userId: UserJSON['id'], budget: BudgetJSON | null) {
  if (isProcessed(approvalRequest, userId)) {
    return false;
  }

  if (approvalRequest.participantUsers.length > 0) {
    return true;
  }

  return budget && isBudgetVisible(budget, userId);
}

export function ApprovalRequestCard({ approvalRequest, budgets, scope, tab }: Props) {
  const dispatch = useAppDispatch();
  const toast = useToast();
  const { t } = useTranslation('approvalRequests');

  const user = useSelector(getUserSelector);

  const { subtotal, totalTax, totalDiscount, total } = getBookingInvoicesTotalDetails(approvalRequest.bookingsInvoices);
  const bookingTotal = subtotal - totalDiscount;

  const budget = useMemo(() => budgets.find(budget => budget.id === approvalRequest.booking.budgetId), [approvalRequest, budgets]);
  const hasBudgetCard = isBudgetCardVisible(approvalRequest, user.id, budget);

  async function onApprovalResponseUpdate(payload: ApprovalResponseUpdatePayload) {
    try {
      await dispatch(updateApprovalResponse({...payload })).unwrap();

      toast(getSuccessToast(t(`toasts.${payload.data.status}.success`)));

      if (payload.data.status === 'REJECTED') {
        // In case of multiple approval responses, other approval responses are canceled
        // We fetch all approval requests to have other approval responses up-to-date
        const params = new URLSearchParams({ status: tab.toUpperCase() });
        dispatch(getApprovalRequests({ scope, params }));
      }
    } catch(error) {
      toast(getErrorToast(t(`toasts.${payload.data.status}.error`), error));
    }
  }

  async function onApprovalResponsesUpdate(payload: ApprovalResponsesUpdatePayload) {
    try {
      await dispatch(updateApprovalResponses(payload)).unwrap();
      toast(getSuccessToast(t(`toasts.${payload.data.status}.success`)));
    } catch(error) {
      toast(getErrorToast(t(`toasts.${payload.data.status}.error`), error));
    }
  }

  return (
    <Box w="100%" bg="white" border="1px solid" borderColor="blue.50" borderRadius={4} overflow="hidden">
      <HStack px={5} py={3} borderBottom="1px solid" borderColor="blue.50" justifyContent="space-between">
        <Heading size="md">{getBookingName(approvalRequest.booking)}</Heading>
        { isAdmin(user) && <EventDetailsLink bookingId={approvalRequest.bookingId} /> }
      </HStack>

      <Grid minW={900} templateColumns="repeat(3, 1fr)">
        <VStack w="100%" p={5} borderRight="1px solid" borderColor="blue.50" spacing={5}>
          <BookingItemsList
            bookingsItems={approvalRequest.booking.bookingsItems}
          />

          <Divider />

          {
            hasBudgetCard && (
              <>
                <BookingBudgetCard
                  bookingTotal={bookingTotal}
                  budget={approvalRequest.booking.budget}
                  budgetBreakdown={approvalRequest.budgetBreakdown}
                  users={approvalRequest.participantUsers}
                  userBudgetBreakdowns={approvalRequest.userBudgetBreakdowns}
                  currency="EUR"
                />
                <Divider />
              </>
            )
          }

          <BookingPriceDetailsCard
            subtotal={subtotal}
            totalTax={totalTax}
            totalDiscount={totalDiscount}
            total={total}
            currency="EUR"
            showCurrencyWarning={true}
          />
        </VStack>

        <ApprovalRequestDetails approvalRequest={approvalRequest} scope={scope} />

        {
          tab === 'processed' ? (
            <ApprovalResponsesList approvalRequest={approvalRequest} />
          ) : (
            <ApprovalResponseForm
              key={approvalRequest.approvalResponses.map(({ id, status }) => `${id}-${status}`).join('-')}
              approvalRequest={approvalRequest}
              onApprovalResponseUpdate={onApprovalResponseUpdate}
              onApprovalResponsesUpdate={onApprovalResponsesUpdate}
              scope={scope}
            />
          )
        }
      </Grid>
    </Box>
  );
}