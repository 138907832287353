import { Box, HStack, Heading, Select, Spacer, Tab, TabList } from '@chakra-ui/react';
import { ApprovalRequestScope } from '@jurnee/common/src/dtos/approvalRequests';
import { isAdmin } from '@jurnee/common/src/utils/user';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { getUserSelector } from 'src/store/user/user.selectors';
import { TabItem, TabKey } from '.';

interface Props {
  tabs: TabItem[];
  scope: ApprovalRequestScope;
  onScopeChange(scope: ApprovalRequestScope): void;
  onTabChange(tab: TabKey): void;
}

export function ApprovalRequestsHeader({ tabs, scope, onScopeChange, onTabChange }: Props) {
  const { t } = useTranslation('approvalRequests', { keyPrefix: 'header' });

  const user = useSelector(getUserSelector);

  return (
    <Box bg="white" w="100%">
      <HStack justifyContent="space-between" p={8} borderColor="blue.50">
        <Heading w="100%">{t('title')}</Heading>
        <Spacer />

        {
          isAdmin(user) &&
            <Select size="sm" w={270} value={scope || ''} onChange={({ target }) => onScopeChange(target.value as ApprovalRequestScope)}>
              <option value="company">{t('scope.company')}</option>
              <option value="approver">{t('scope.approver')}</option>
            </Select>
        }
      </HStack>

      <TabList>
        { tabs.map(({ key, label }) => <Tab key={key} onClick={() => onTabChange(key)}>{label}</Tab>) }
      </TabList>
    </Box>
  );
}