import {
  TabPanel,
  TabPanels,
  Tabs,
  useToast,
  VStack
} from '@chakra-ui/react';
import { Loader } from '@jurnee/common/src/components/Loader';
import { ApprovalRequestScope } from '@jurnee/common/src/dtos/approvalRequests';
import { BudgetJSON } from '@jurnee/common/src/entities/Budget';
import { getErrorToast } from '@jurnee/common/src/utils/toasts';
import { isAdmin } from '@jurnee/common/src/utils/user';
import { getApprovalRequestListJSON, getApprovalRequestsFetchStatusSelector } from '@jurnee/dashboard/src/store/approvalRequests/approvalRequests.selectors';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import EmptyState from 'src/components/EmptyState';
import { useAppDispatch } from 'src/store';
import { getApprovalRequests } from 'src/store/approvalRequests/approvalRequests.thunks';
import { getBudgets, getUserBudgets } from 'src/store/budgets/budgets.thunks';
import { getUserSelector } from 'src/store/user/user.selectors';
import { getUserStatsFetchStatus, getUserStatsSelector } from 'src/store/userStats/userStats.selectors';
import { ApprovalRequestCard } from './ApprovalRequestCard';
import { ApprovalRequestsHeader } from './ApprovalRequestsHeader';

export type TabKey = 'pending' | 'processed';

export interface TabItem {
  label: string;
  key: TabKey;
}

export function ApprovalRequests() {
  const dispatch = useAppDispatch();
  const toast = useToast();
  const { t } = useTranslation('approvalRequests');

  const user = useSelector(getUserSelector);
  const userStats = useSelector(getUserStatsSelector);
  const userStatsFetchStatus = useSelector(getUserStatsFetchStatus);
  const approvalRequests = useSelector(getApprovalRequestListJSON);
  const approvalRequestsFetchStatus = useSelector(getApprovalRequestsFetchStatusSelector);

  const [tab, setTab] = useState<TabKey>('pending');
  const [budgets, setBudgets] = useState<BudgetJSON[]>([]);
  const [areBudgetsLoading, setAreBudgetsLoading] = useState(true);
  const [scope, setScope] = useState<ApprovalRequestScope>(null);

  const isLoading = approvalRequestsFetchStatus !== 'FETCHED' || areBudgetsLoading;
  const areUserStatsLoading = userStatsFetchStatus !== 'FETCHED';

  const tabs: TabItem[] = [{
    label: t('header.tabs.pending'),
    key: 'pending'
  }, {
    label: t('header.tabs.processed'),
    key: 'processed'
  }];

  async function fetchBudgets() {
    try {
      const { list } = await dispatch(isAdmin(user) ? getBudgets() : getUserBudgets()).unwrap();
      setBudgets(list);
      setAreBudgetsLoading(false);
    } catch(error) {
      toast(getErrorToast(t('toasts.fetchBudgets.error'), error.message));
    }
  }

  useEffect(() => {
    fetchBudgets();
  }, []);

  useEffect(() => {
    if (!areUserStatsLoading) {
      setScope(isAdmin(user) && userStats.approvalProcesses === 0 ? 'company' : 'approver');
    }
  }, [userStatsFetchStatus]);

  useEffect(() => {
    if (scope) {
      const params = new URLSearchParams({ status: tab.toUpperCase() });
      dispatch(getApprovalRequests({ scope, params }));
    }
  }, [scope, tab]);

  if (areUserStatsLoading) {
    return <Loader />;
  }

  function getApprovalRequestsByKey(key: TabItem['key']) {
    if (approvalRequests.length === 0) {
      return <EmptyState
        imagePath="/assets/illustrations/approval.svg"
        heading={t(`${key}.emptyState.heading`)}
        description={t(`${key}.emptyState.description`)}
      />;
    }

    return (
      <VStack spacing={5}>
        {
          approvalRequests.map(approvalRequest => (
            <ApprovalRequestCard
              key={approvalRequest.id}
              approvalRequest={approvalRequest}
              budgets={budgets}
              tab={tab}
              scope={scope}
            />
          ))
        }
      </VStack>
    );
  }

  return (
    <main>
      <Tabs index={tabs.findIndex(({ key }) => key === tab)} isLazy>
        <ApprovalRequestsHeader tabs={tabs} onTabChange={setTab} scope={scope} onScopeChange={setScope} />

        <TabPanels>
          {
            tabs.map(({ key }) => (
              <TabPanel key={key}>
                {
                  isLoading ?
                    <Loader h={400} /> :
                    getApprovalRequestsByKey(key)
                }
              </TabPanel>
            ))
          }
        </TabPanels>
      </Tabs>
    </main>
  );
}