import { Box, Divider, Flex, HStack, Image, MenuList, Text, VStack } from '@chakra-ui/react';
import { Icon } from '@jurnee/common/src/components/Icon';
import { MenuItemWrapper } from '@jurnee/common/src/components/MenuItemWrapper';
import '@jurnee/common/src/components/Navbar.css';
import { NavbarLink } from '@jurnee/common/src/components/NavbarLink';
import { UserCard } from '@jurnee/common/src/components/UserCard';
import { BookingJSON } from '@jurnee/common/src/entities/Booking';
import { UserStatsJSON } from '@jurnee/common/src/entities/User';
import { isAdmin } from '@jurnee/common/src/utils/user';
import * as React from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { NavLink } from 'react-router-dom';
import UserSettingsDrawer from 'src/drawers/UserSettingsDrawer';
import { CreateBookingModal } from 'src/modals/CreateBookingModal';
import router from 'src/router';
import { TrackEventOptions, trackEvent } from 'src/store/analytics/analytics.thunks';
import { logout } from 'src/store/auth/auth.store';
import { getActionRequiredBookingsSelector } from 'src/store/bookings/bookings.selectors';
import { RootState } from 'src/store/state';
import { getUserStatsSelector } from 'src/store/userStats/userStats.selectors';

interface StateProps {
  actionRequiredBookings: BookingJSON[];
  company: RootState['company'];
  user: RootState['user'];
  userStats: UserStatsJSON;
}

interface DispatchProps {
  trackEvent(opts: TrackEventOptions): void;
  logout(): void;
}

type Props = StateProps & DispatchProps & WithTranslation;

class Navbar extends React.PureComponent<Props> {

  getSection(label: string, links: JSX.Element[]) {
    return (
      <Box w="100%">
        <Text fontSize={12} color="gray.500">{label}</Text>
        <VStack mt={3} spacing={3}>{...links}</VStack>
      </Box>
    );
  }

  get account() {
    const links = [
      <NavbarLink to='/company/settings' icon='settings' label={this.props.t('company.settings')} />,
      <NavbarLink to='/company/people' icon='group' label={this.props.t('company.people')} />,
      <NavbarLink to='/company/billing' icon='file' label={this.props.t('company.billing')} />
    ];

    return this.getSection('COMPANY', links);
  }

  get shouldDisplayApprovalRequestsNavLink() {
    if (isAdmin(this.props.user)) {
      return true;
    }

    if (this.props.userStats?.approvalProcesses > 0) {
      return true;
    }

    return false;
  }

  onClick = () => {
    this.props.trackEvent({ name: 'custom_request_button_clicked' });
  };

  render() {
    return (
      <Flex flexDirection="column" minW="256px" h="100%" bg="white" px={5} pb={5} gap={4}>
        <NavLink to="/">
          <HStack className="jurnee-logo" justifyContent="flex-start" h="70px" w="100%" px={2}>
            <Image src="/assets/images/logo_full.svg" h="29px" />
          </HStack>
        </NavLink>

        <CreateBookingModal>
          <HStack className="nav-new-event" h="32px" alignItems="center" spacing={2} px={3} borderRadius={4}>
            <Icon icon="calendarNew" color="white" size={5} />
            <Text fontSize={14} lineHeight="14px" color="white" fontWeight={700}>
              {this.props.t('newEvent')}
            </Text>
          </HStack>
        </CreateBookingModal>

        <VStack w="100%" spacing={3}>
          <NavbarLink
            to='/experiences'
            icon='compass'
            label={this.props.t('explore')}
          />
          <NavbarLink
            to='/bookings'
            icon='calendar'
            label={this.props.t('bookings')}
            count={this.props.actionRequiredBookings.length}
          />
          <NavbarLink
            to='/tasks'
            icon='checkList'
            label={this.props.t('tasks')}
            count={this.props.userStats?.tasks.pending}
          />
          {
            this.shouldDisplayApprovalRequestsNavLink &&
              <NavbarLink
                to='/approval-requests'
                icon='pendingCheck'
                label={this.props.t('approvalRequests')}
                count={this.props.userStats?.approvalResponses.initial}
              />
          }
        </VStack>

        { isAdmin(this.props.user) && this.account }

        <Flex marginTop='auto' flexDirection='column' gap={5}>
          <HStack id="intercom-launcher" color="gray.500" _hover={{ 'svg path': { fill: 'black' }, color: 'black' }} cursor="pointer" alignItems="center" spacing={2} px={3}>
            <Icon icon="chat" color="gray.500" size={5} />
            <Text fontSize={14} fontWeight={500} noOfLines={1}>{this.props.t('support')}</Text>
          </HStack>

          <Divider />

          <UserCard user={this.props.user} organization={this.props.company.data}>
            <MenuList>
              <MenuItemWrapper
                icon='heartLine'
                label={this.props.t('userCard.favorites')}
                onClick={() => router.navigate('/favorites')}
              />
              <MenuItemWrapper
                icon='folderIn'
                label={this.props.t('userCard.lists')}
                onClick={() => router.navigate('/collections')}
              />
              <UserSettingsDrawer />
              <MenuItemWrapper
                icon='logout'
                label={this.props.t('userCard.logout')}
                onClick={() => this.props.logout()}
              />
            </MenuList>
          </UserCard>
        </Flex>
      </Flex>
    );
  }
}

function mapStateToProps(state: RootState): StateProps {
  return {
    actionRequiredBookings: getActionRequiredBookingsSelector(state),
    company: state.company,
    user: state.user,
    userStats: getUserStatsSelector(state)
  };
}

const mapDispatchToProps: DispatchProps = {
  trackEvent,
  logout
};

export default connect<StateProps, DispatchProps>(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation('navbar')(Navbar));