import { FormControl, FormLabel, HStack, Select, Textarea, VStack } from '@chakra-ui/react';
import { ApprovalRequestScope } from '@jurnee/common/src/dtos/approvalRequests';
import { ApprovalResponseJSON } from '@jurnee/common/src/entities/ApprovalResponse';
import { ConfirmModal } from '@jurnee/common/src/modals/ConfirmModal';
import { getUserLabel } from '@jurnee/common/src/utils/user';
import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { ProcessNumberInput } from 'src/components/ProcessNumberInput';
import { SecondaryButton } from 'src/components/buttons';
import { ApprovalRequestData } from 'src/store/approvalRequests/approvalRequests.selectors';
import { ApprovalResponsesUpdatePayload, ApprovalResponseUpdatePayload } from 'src/store/approvalRequests/approvalRequests.thunks';
import { getUserSelector } from 'src/store/user/user.selectors';
import { getApproverApprovalResponse } from 'src/utils/approvalRequest';

interface Props {
  approvalRequest: ApprovalRequestData;
  scope: ApprovalRequestScope;
  onApprovalResponseUpdate(payload: ApprovalResponseUpdatePayload): Promise<void>;
  onApprovalResponsesUpdate(payload: ApprovalResponsesUpdatePayload): Promise<void>;
}

export function ApprovalResponseForm(props: Props) {
  const { t } = useTranslation('approvalRequests');

  const user = useSelector(getUserSelector);

  const [processNumber, setProcessNumber] = useState(props.approvalRequest.booking.processNumber);
  const [comment, setComment] = useState(null);
  const [approvalResponseId, setApprovalResponseId] = useState(getDefaultApprovalResponseId());
  const [isUpdating, setIsUpdating] = useState(false);

  const approvalResponses = useMemo(() => props.approvalRequest.approvalResponses.filter(({ status }) => ['INITIAL', 'PENDING'].includes(status)), [props.approvalRequest]);

  function getDefaultApprovalResponseId() {
    if (props.scope === 'approver') {
      return getApproverApprovalResponse(props.approvalRequest, user.id).id;
    }

    const unprocessed = props.approvalRequest.approvalResponses.filter(({ status }) => ['INITIAL', 'PENDING'].includes(status));

    return unprocessed.length === 1 ? unprocessed[0].id : 0;
  }

  async function onApprovalResponseUpdate(status: ApprovalResponseJSON['status']) {
    setIsUpdating(true);

    await props.onApprovalResponseUpdate({
      id: approvalResponseId,
      approvalRequestId: props.approvalRequest.id,
      data: {
        processNumber,
        comment,
        status
      },
      scope: props.scope
    });

    setIsUpdating(false);
  };

  async function onApprovalResponsesUpdate(status: ApprovalResponseJSON['status']) {
    setIsUpdating(true);

    await props.onApprovalResponsesUpdate({
      approvalRequestId: props.approvalRequest.id,
      data: {
        processNumber,
        comment,
        status
      }
    });

    setIsUpdating(false);
  };

  function onConfirm(status: ApprovalResponseJSON['status']) {
    props.scope === 'company' && !approvalResponseId ? onApprovalResponsesUpdate(status) : onApprovalResponseUpdate(status);
  }

  return (
    <VStack w="100%" p={5} spacing={4}>
      <ProcessNumberInput
        size="sm"
        defaultValue={processNumber}
        onChange={({ target }) => setProcessNumber(target.value)}
      />

      <FormControl id="comment">
        <FormLabel lineHeight="16px" mb={2}>{t('approvalResponse.form.comment.label')}</FormLabel>
        <Textarea
          size="sm"
          name="comment"
          minHeight="55px"
          fontSize="14px"
          resize="none"
          placeholder={t('approvalResponse.form.comment.placeholder')}
          _placeholder={{ color: 'gray.400' }}
          onChange={({ target }) => setComment(target.value)}
          defaultValue={comment}
        />
      </FormControl>

      {
        props.scope === 'company' &&
          <FormControl id="replyAs">
            <FormLabel lineHeight="16px" mb={2}>{t('approvalResponse.form.replyAs.label')}</FormLabel>
            <Select size="sm" value={approvalResponseId.toString()} onChange={({ target }) => setApprovalResponseId(Number(target.value))}>
              {
                approvalResponses.length > 1 &&
                  <option value={0}>{t('approvalResponse.form.replyAs.all')}</option>
              }
              {
                approvalResponses.map(({ id, userId }) => {
                  const user = props.approvalRequest.users.find(user => user.id === userId);
                  return <option key={id} value={id}>{getUserLabel(user)}</option>;
                })
              }
            </Select>
          </FormControl>
      }

      <HStack w="100%" spacing={4}>
        <ConfirmModal
          title={t('approvalResponse.confirmationModal.reject.title')}
          message={t('approvalResponse.confirmationModal.reject.message')}
          buttonLabel={t('approvalResponse.reject')}
          onConfirm={() => onConfirm('REJECTED')}
        >
          <SecondaryButton size="sm" w="50%" colorScheme="pink" type="submit" isDisabled={isUpdating}>
            { t('approvalResponse.reject') }
          </SecondaryButton>
        </ConfirmModal>

        <ConfirmModal
          buttonColor="teal"
          title={t('approvalResponse.confirmationModal.approve.title')}
          message={t('approvalResponse.confirmationModal.approve.message')}
          buttonLabel={t('approvalResponse.approve')}
          onConfirm={() => onConfirm('APPROVED')}
        >
          <SecondaryButton size="sm" w="50%" colorScheme="teal" type="submit" isDisabled={isUpdating}>
            { t('approvalResponse.approve') }
          </SecondaryButton>
        </ConfirmModal>
      </HStack>
    </VStack>
  );
}