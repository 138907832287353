import { StatusBadge } from '@jurnee/common/src/components/StatusBadge';
import { ApprovalResponseJSON } from '@jurnee/common/src/entities/ApprovalResponse';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

interface Props {
  status: ApprovalResponseJSON['status'];
}

export function ApprovalResponseStatus(props: Props) {
  const { t } = useTranslation('common', { keyPrefix: 'status' });

  const label = useMemo(() => {
    switch (props.status) {
    case 'APPROVED': return t('approved');
    case 'CANCELED': return t('canceled');
    case 'REJECTED': return t('rejected');
    default: return t('pending');
    }
  }, [props.status]);

  const color = useMemo(() => {
    switch (props.status) {
    case 'CANCELED': return 'gray';
    case 'APPROVED': return 'teal';
    case 'REJECTED': return 'pink';
    default: return 'blue';
    }
  }, [props.status]);

  return <StatusBadge color={color} label={label} />;
}