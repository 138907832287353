import { ApprovalResponse, ApprovalResponseJSON } from '@jurnee/common/src/entities/ApprovalResponse';
import { ApprovalRequestData } from 'src/store/approvalRequests/approvalRequests.selectors';

export function isProcessed(approvalRequest: ApprovalRequestData, approverId: ApprovalResponse['userId']): boolean {
  if (approvalRequest.approvalResponses.some(({ status, userId }) => status !== 'INITIAL' && userId === approverId)) {
    return true;
  }

  if (approvalRequest.approvalResponses.every(({ status }) => !['INITIAL', 'PENDING'].includes(status))) {
    return true;
  }

  return false;
}

export function getApproverApprovalResponse(approvalRequest: ApprovalRequestData, approverId: ApprovalResponse['userId']): ApprovalResponseJSON {
  return approvalRequest.approvalResponses.find((approvalResponse): approvalResponse is ApprovalResponseJSON => {
    return approvalResponse.userId === approverId;
  });
}