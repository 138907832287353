import { ApprovalRequestCreateBody, ApprovalRequestScope } from '@jurnee/common/src/dtos/approvalRequests';
import { ApprovalResponseUpdateBody } from '@jurnee/common/src/dtos/approvalResponses';
import ApprovalRequestEntity, { ApprovalRequestJSON, ApprovalRequestRelationshipsJSON } from '@jurnee/common/src/entities/ApprovalRequest';
import { ApprovalResponseJSON } from '@jurnee/common/src/entities/ApprovalResponse';
import { BookingJSON } from '@jurnee/common/src/entities/Booking';
import { CompanyJSON } from '@jurnee/common/src/entities/Company';
import { List } from '@jurnee/common/src/serializers';
import { createAsyncThunk } from '@reduxjs/toolkit';
import api from '../../api/approvalRequests';
import bookingsApi from '../../api/bookings';
import companyApi from '../../api/company';
import { getBookings } from '../bookings/bookings.thunks';
import { RootState } from '../state';
import { showToast } from '../toasts/toasts.thunks';
import { getUserStatsThunk } from '../userStats/userStats.thunks';

export interface ApprovalResponsesUpdatePayload {
  approvalRequestId: ApprovalRequestEntity['id'];
  data: ApprovalResponseUpdateBody;
}

export interface ApprovalResponseUpdatePayload extends ApprovalResponsesUpdatePayload {
  id: ApprovalResponseJSON['id'];
  scope: ApprovalRequestScope;
}

export interface ApprovalRequestCreatePayload {
  id: BookingJSON['id'];
  data: ApprovalRequestCreateBody;
}

async function getApprovalRequestsByScope(companyId: CompanyJSON['id'], scope: ApprovalRequestScope, params: URLSearchParams): Promise<List<ApprovalRequestJSON, ApprovalRequestRelationshipsJSON>> {
  return scope === 'approver' ? api.getApprovalRequests(params) : companyApi.getApprovalRequestsByCompany(companyId, params);
}

export const getApprovalRequests = createAsyncThunk<List<ApprovalRequestJSON, ApprovalRequestRelationshipsJSON>, { scope: ApprovalRequestScope } & { params: URLSearchParams }, { state: RootState }>('APPROVAL_REQUESTS_GET', async ({ scope, params }, thunkAPI) => {
  try {
    const { company } = thunkAPI.getState();

    const approvalRequests = await getApprovalRequestsByScope(company.data.id, scope, params);
    return approvalRequests;
  } catch (e) {
    thunkAPI.dispatch(showToast({ title: `An error occurred while retrieving your approval requests`, status: 'error' }));
    return thunkAPI.rejectWithValue(e.message);
  }
});

export const createApprovalRequest = createAsyncThunk<ApprovalRequestJSON, ApprovalRequestCreatePayload, { state: RootState }>('APPROVAL_REQUESTS_CREATE', async ({ id, data }, thunkAPI) => {
  try {
    const approvalRequest = await bookingsApi.createApprovalRequest(id, data);
    const searchParams = new URLSearchParams({ after: Date.now().toString() });

    thunkAPI.dispatch(getBookings({ scope: 'user', searchParams }));

    thunkAPI.dispatch(getUserStatsThunk());

    return approvalRequest;
  } catch (err) {
    return thunkAPI.rejectWithValue(err.message);
  }
});

export const updateApprovalResponse = createAsyncThunk<ApprovalResponseJSON, ApprovalResponseUpdatePayload, { state: RootState }>('APPROVAL_RESPONSE_UPDATE', async ({ id, approvalRequestId, data }, thunkAPI) => {
  try {
    const updatedApprovalResponse = await api.updateApprovalResponse(approvalRequestId, id, data);

    const searchParams = new URLSearchParams({ after: Date.now().toString() });
    thunkAPI.dispatch(getBookings({ scope: 'user', searchParams }));

    thunkAPI.dispatch(getUserStatsThunk());

    return updatedApprovalResponse;
  } catch (err) {
    return thunkAPI.rejectWithValue(err.message);
  }
});

export const updateApprovalResponses = createAsyncThunk<ApprovalRequestJSON, ApprovalResponsesUpdatePayload, { state: RootState }>('APPROVAL_RESPONSES_UPDATE', async ({ approvalRequestId, data }, thunkAPI) => {
  try {
    const approvalRequest = await api.updateApprovalResponses(approvalRequestId, data);

    const searchParams = new URLSearchParams({ after: Date.now().toString() });
    thunkAPI.dispatch(getBookings({ scope: 'user', searchParams }));

    thunkAPI.dispatch(getUserStatsThunk());

    return approvalRequest;
  } catch (err) {
    return thunkAPI.rejectWithValue(err.message);
  }
});